import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Spinner, Alert, Accordion } from 'react-bootstrap';
import { getUserOrders } from '../actions/orderActions';
import { toast } from 'react-toastify';

const MyOrders = () => {
  const dispatch = useDispatch();
  const { orders, loading, error } = useSelector(state => state.order);
  const { client } = useSelector(state => state.auth); // Obtener el cliente del estado auth

  useEffect(() => {
    if (client && client.uuid) {
      dispatch(getUserOrders(client.uuid));
    }
  }, [dispatch, client]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Container>
      <h2>Mis Órdenes</h2>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <>
          {orders.length === 0 ? (
            <Alert variant="info">No tienes órdenes.</Alert>
          ) : (
            <Accordion>
              {orders?.map((order, index) => (
                <Card key={order.uuid} className="mb-3">
                  <Accordion.Item eventKey={String(index)}>
                    <Accordion.Header>
                      <div>
                        <strong>Orden {order.code}</strong><br />
                        <strong>Fecha:</strong> {new Date(order.purchase_date).toLocaleString()}<br />
                        {/* <strong>Estado:</strong> {order.status_name} */}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Card.Text>
                        <strong>Establecimiento:</strong> {order.establishment_name}<br />
                        <strong>Total de productos:</strong> {order.total_quantity}
                      </Card.Text>
                      <hr />
                      <h5>Productos:</h5>
                      <ul>
                        {order?.details?.map(detail => (
                          <li key={detail.product_uuid}>
                            <strong>{detail.product_name}</strong>: {detail.quantity} unidades
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Card>
              ))}
            </Accordion>
          )}
        </>
      )}
    </Container>
  );
};

export default MyOrders;
