import { combineReducers } from 'redux';
import authReducer from './authReducer';
import kioskoReducer from './kioskoReducer';
import cartReducer from './cartReducer';
import orderReducer from './orderReducer';

const appReducer = combineReducers({
  auth: authReducer,
  kiosko: kioskoReducer,
  cart: cartReducer,
  order: orderReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
