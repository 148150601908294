import React from 'react';
import './styles/NotFound.css';
import logo from '../imgs/saigro-kiosko-logo-no-bg.svg'; // Asegúrate de tener el logo correcto
import { Container } from 'react-bootstrap';

const NotFound = () => {
  return (
    <Container className="notfound-container">
      <img src={logo} alt="Logo" className="notfound-logo" />
      <h1>ERROR 404</h1>
      <h2>Upss...</h2>
      <p>Parece que el famoso <strong>"error 404"</strong> decidió aparecer.</p>
      <p>Pero no te preocupes podés volver a recorrer cualquier parte de nuestra web mientras lo solucionamos!</p>
    </Container>
  );
};

export default NotFound;
