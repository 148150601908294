// src/reducers/orderReducer.js
const initialState = {
  orders: [],
  loading: false,
  error: null,
  successMessage: ''
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_ORDER_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
        successMessage: ''
      };
    case 'CREATE_ORDER_SUCCESS':
      return {
        ...state,
        loading: false,
        orders: [...state.orders, action.payload],
        successMessage: 'Pedido confirmado. A la brevedad se estará resolviendo.'
      };
    case 'CREATE_ORDER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
        successMessage: ''
      };
    case 'GET_USER_ORDERS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'GET_USER_ORDERS_SUCCESS':
      return {
        ...state,
        loading: false,
        orders: action.payload
      };
    case 'GET_USER_ORDERS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default orderReducer;
