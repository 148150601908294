// src/components/Home.jsx
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductItem from './ProductItem';
import SearchBar from './SearchBar';
import PaginationComponent from './Pagination';
import './styles/Home.css';
import { Container, Row, Col } from 'react-bootstrap';

const Home = () => {
  const dispatch = useDispatch();
  const selectedKiosko = useSelector(state => state.kiosko.selectedKiosko);
  const user = useSelector(state => state.auth.user);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  // Filtrar productos del kiosko seleccionado y por término de búsqueda
  const filteredProducts = selectedKiosko && user
    ? user.establishments.find(establishment => establishment.uuid === selectedKiosko).products
    .filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name))
    : [];

  // Calcular los productos a mostrar en la página actual
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const addToCart = (product, quantity) => {
    dispatch({ type: 'ADD_TO_CART', payload: { product, quantity } });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <div className="product-grid">
        {currentProducts.map(product => (
          <ProductItem key={product.uuid} product={product} addToCart={addToCart} />
        ))}
      </div>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
