// src/components/ProductItem.jsx
import React, { useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './styles/ProductItem.css';
import noPhotoProduct from '../imgs/no-product-photo.png';

const ProductItem = ({ product, addToCart }) => {
  const [quantity, setQuantity] = useState(1);

  const handleAddToCart = () => {
    toast.success(`${product.name} agregado al carrito!`);
    addToCart(product, quantity);
    setQuantity(1);
  };

  const imageSrc = product?.photos?.length > 0 ? `data:image/png;base64,${product.photos[0].blob}` : noPhotoProduct;

  return (
    <Card className="product-item">
      <div className="product-image-container">
        <img src={imageSrc} alt={product.name} className="product-image" />
      </div>
      <div className="product-body">
        <div className="product-info">
          <Card.Title className="product-title">{product.name}</Card.Title>
          {product.description && <Card.Text className="product-description">{product.description}</Card.Text>}
        </div>
        <div className="product-actions">
          <Form.Control
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            min="1"
            className="product-quantity"
          />
          <Button onClick={handleAddToCart} className="product-add-button">+</Button>
        </div>
      </div>
    </Card>
  );
};

export default ProductItem;
