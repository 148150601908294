export const login = (username, password) => {
  return async (dispatch) => {
    dispatch({ type: 'LOGIN_REQUEST' });
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Something went wrong');
      }
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
          token: data.user.auth_token,
          user: data.user,
          // employee: data.employee || null,
          client: data.client || null
        }
      });
      localStorage.setItem('authToken', data.user.auth_token);
    } catch (error) {
      dispatch({
        type: 'LOGIN_FAILURE',
        payload: error.message
      });
    }
  };
};

export const logout = () => ({
  type: 'LOGOUT'
});