import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Form, Container, Spinner } from 'react-bootstrap';
import { FaTrashAlt, FaCheckCircle, FaShoppingCart } from 'react-icons/fa';
import { createOrder } from '../actions/orderActions';
import { toast } from 'react-toastify';
import './styles/Cart.css';
import noPhotoProduct from '../imgs/no-product-photo.png';

const Cart = () => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveFromCart = (product) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: product });
  };

  const handleQuantityChange = (product, quantity) => {
    dispatch({ type: 'UPDATE_CART_QUANTITY', payload: { product, quantity } });
  };

  const handleCheckout = async () => {
    setIsLoading(true);

    try {
      await dispatch(createOrder(cart));
      toast.success('Pedido confirmado. A la brevedad se estará resolviendo.');
      dispatch({ type: 'CLEAR_CART' });
    } catch (error) {
      toast.error('Hubo un error al procesar tu pedido. Por favor, intenta nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <div className='carrito-title-container'>
        <FaShoppingCart className='carrito-icon-title' />
        <h2 className='carrito-title'>Carrito de Compras</h2>
      </div>
      {cart.length === 0 ? (
        <p>El carrito está vacío.</p>
      ) : (
        cart.map(item => (
          <Card key={item.product.uuid} className="cart-item">
            <div className="cart-item-image-container">
              <img
                src={item.product.photos.length > 0 ? `data:image/png;base64,${item.product.photos[0].blob}` : noPhotoProduct}
                alt={item.product.name}
                className="cart-item-image"
              />
            </div>
            <div className="cart-item-body">
              <div className="cart-item-info">
                <Card.Title className="cart-item-title">{item.product.name}</Card.Title>
                {item.product.description && <Card.Text className="cart-item-description">{item.product.description}</Card.Text>}
              </div>
              <div className="cart-item-actions">
                <Form.Control
                  type="number"
                  value={item.quantity}
                  onChange={(e) => handleQuantityChange(item.product, parseInt(e.target.value))}
                  min="1"
                  className="cart-item-quantity"
                />
                <Button variant="danger" onClick={() => handleRemoveFromCart(item.product)} className="cart-item-remove-button">
                  <FaTrashAlt />
                </Button>
              </div>
            </div>
          </Card>
        ))
      )}
      {cart.length > 0 && (
        <div className="cart-actions">
          <Button onClick={handleCheckout} className="confirm-purchase-button" disabled={isLoading}>
            {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaCheckCircle className="confirm-purchase-icon" />}
            {isLoading ? ' Procesando...' : ' Confirmar compra'}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default Cart;
