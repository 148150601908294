// src/actions/orderActions.js
export const createOrder = (cart) => {
  return async (dispatch, getState) => {
    const { token, client } = getState().auth; // Obteniendo el uuid_client desde auth.client
    const { selectedKiosko } = getState().kiosko; // Obteniendo el uuid_establishment desde kiosko.selectedKiosko
    dispatch({ type: 'CREATE_ORDER_REQUEST' });
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/orders/dinning`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          uuid_client: client.uuid, // Incluyendo el uuid_client
          uuid_establishment: selectedKiosko, // Incluyendo el uuid_establishment
          details: cart.map(item => ({
            product_uuid: item.product.uuid,
            quantity: item.quantity,
            description: null
          }))
        })
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }
      dispatch({
        type: 'CREATE_ORDER_SUCCESS',
        payload: data
      });
    } catch (error) {
      dispatch({
        type: 'CREATE_ORDER_FAILURE',
        payload: error.message
      });
    }
  };
};

// src/actions/orderActions.js
export const getUserOrders = (clientUuid) => {
  return async (dispatch, getState) => {
    const { token } = getState().auth; // Obteniendo el token desde el estado
    dispatch({ type: 'GET_USER_ORDERS_REQUEST' });
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/orders/my-orders/${clientUuid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }

      const filteredOrders = data.filter(order => order.status_code === 'END');

      dispatch({
        type: 'GET_USER_ORDERS_SUCCESS',
        payload: filteredOrders
      });
    } catch (error) {
      dispatch({
        type: 'GET_USER_ORDERS_FAILURE',
        payload: error.message
      });
    }
  };
};
