// src/reducers/cartReducer.js
const initialState = [];

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingProduct = state.find(item => item.product.uuid === action.payload.product.uuid);
      if (existingProduct) {
        return state.map(item =>
          item.product.uuid === action.payload.product.uuid
            ? { ...item, quantity: item.quantity + action.payload.quantity }
            : item
        );
      }
      return [...state, action.payload];

    case 'REMOVE_FROM_CART':
      return state.filter(item => item.product.uuid !== action.payload.uuid);

    case 'UPDATE_CART_QUANTITY':
      return state.map(item =>
        item.product.uuid === action.payload.product.uuid
          ? { ...item, quantity: action.payload.quantity }
          : item
      );

    case 'CLEAR_CART':
      return [];

    default:
      return state;
  }
};

export default cartReducer;
