// src/reducers/authReducer.js

const initialState = {
  token: null,
  user: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  employee: null,
  client: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
        loading: false,
        error: null,
        employee: action.payload.employee,
        client: action.payload.client
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      return initialState;
    case 'UPDATE_PROFILE_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'UPDATE_PROFILE_SUCCESS':
      return {
        ...state,
        user: action.payload.user,
        employee: action.payload.employee,
        client: action.payload.client,
        loading: false,
        error: null
      };
    case 'LOGIN_FAILURE':
    case 'UPDATE_PROFILE_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
