import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login';
import Home from './components/Home';
import EditProfile from './components/EditProfile';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SelectKiosko from './components/SelectKiosko';
import Cart from './components/Cart';
import NotFound from './components/NotFound';
import MyOrders from './components/MyOrders';
import './App.css';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const selectedKiosko = useSelector(state => state.kiosko.selectedKiosko);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!selectedKiosko && location.pathname !== '/select-kiosko') {
    return <Navigate to="/select-kiosko" />;
  }

  return children;
};

const AppWrapper = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <div className="app-container">
      {!isLoginPage && <Navbar />}  {/* Renderizar el navbar solo si no estamos en la página de login */}
      <div className="content">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          } />
          <Route path="/edit-profile" element={
            <PrivateRoute>
              <EditProfile />
            </PrivateRoute>
          } />
          <Route path="/select-kiosko" element={
            <PrivateRoute>
              <SelectKiosko />
            </PrivateRoute>
          } />
          <Route path="/cart" element={
            <PrivateRoute>
              <Cart />
            </PrivateRoute>
          } />
          <Route path="/my-orders" element={
            <PrivateRoute>
              <MyOrders />
            </PrivateRoute>
          } />
          <Route path="*" element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          } />
        </Routes>
      </div>
      {/* <ToastContainer /> */}
      <Footer />
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

export default App;
