import React from 'react';
import './styles/Footer.css';

const Footer = () => (
  <footer className="footer-container">
    Desarrollado por <a href="https://www.somoscorpora.com/" className="footer-link" target="_blank" rel="noopener noreferrer">Corpora</a>
  </footer>
);

export default Footer;
