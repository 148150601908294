import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Container, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './styles/EditProfile.css';

const EditProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const employee = useSelector(state => state.auth.employee);
  const client = useSelector(state => state.auth.client);

  const [username, setUsername] = useState(user.username);
  const [firstName, setFirstName] = useState(employee?.first_name || client?.first_name || '');
  const [lastName, setLastName] = useState(employee?.last_name || client?.last_name || '');
  const [email, setEmail] = useState(employee?.email || client?.email || '');
  const [phone, setPhone] = useState(employee?.phone || client?.phone || '');
  const [birthDate, setBirthDate] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');

  useEffect(() => {
    if (employee?.birth_date) {
      setBirthDate(employee.birth_date.split('T')[0]);
    } else if (client?.birth_date) {
      setBirthDate(client.birth_date.split('T')[0]);
    }
  }, [employee, client]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedProfile = {
      first_name: firstName,
      last_name: lastName,
      phone,
      email,
      ...(client && { birth_date: birthDate })
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/${client ? 'clients' : 'employees'}/${client ? client.uuid : employee.uuid}/himself`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(updatedProfile)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'An error occurred while updating the profile');
      }

      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleShowChangePassword = () => setShowChangePassword(true);
  const handleCloseChangePassword = () => setShowChangePassword(false);

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/change_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          current_password: oldPassword,
          new_password: password
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'An error occurred while changing the password');
      }

      setOldPassword("");
      setPassword("");
      setConfirmPassword("");
      toast.success(data.message);
      handleCloseChangePassword();
    } catch (error) {
      toast.error(error.message);
      setOldPassword("");
      setPassword("");
      setConfirmPassword("");
      handleCloseChangePassword();
    }
  };

  return (
    <Container className="mt-4">
      <h2>Editar perfil</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username" className="mt-3">
          <Form.Label>Nombre de usuario</Form.Label>
          <Form.Control
            type="text"
            value={username}
            disabled
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="firstName" className="mt-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="lastName" className="mt-3">
          <Form.Label>Apellido</Form.Label>
          <Form.Control
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="email" className="mt-3">
          <Form.Label>Dirección de correo electrónico</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="phone" className="mt-3">
          <Form.Label>Número de teléfono celular</Form.Label>
          <Form.Control
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </Form.Group>
        {client && (
          <Form.Group controlId="birthDate" className="mt-3">
            <Form.Label>Fecha de nacimiento</Form.Label>
            <Form.Control
              type="date"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </Form.Group>
        )}
        <Form.Group className="text-center">
          <Button variant="primary" onClick={handleShowChangePassword} className="mt-3 mx-2">
            Cambiar contraseña
          </Button>
        <Button type="submit" className="mt-3 mx-2">Actualizar perfil</Button>
        </Form.Group>
      </Form>

      <Modal show={showChangePassword} onHide={handleCloseChangePassword}>
        <Modal.Header closeButton>
          <Modal.Title>Cambiar contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="oldPassword" className="mt-3">
            <Form.Label>Contraseña anterior</Form.Label>
            <Form.Control
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="newPassword" className="mt-3">
            <Form.Label>Nueva contraseña</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="confirmNewPassword" className="mt-3">
            <Form.Label>Confirmar nueva contraseña</Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseChangePassword}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleChangePassword}>
            Cambiar contraseña
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EditProfile;
