import React from 'react';
import { Navbar as BootstrapNavbar, Nav, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaShoppingCart, FaClipboardList, FaBox, FaUserEdit, FaSignOutAlt, FaExchangeAlt } from 'react-icons/fa';
import logo from '../imgs/saigro-kiosko-logo-no-bg.png';
import logo2 from '../imgs/LOGOTIPO_BLANCO.png';
import logoUser from '../imgs/no-user-img.png';
import { logout } from '../actions/authActions';  // Importar la acción de logout
import "./styles/Navbar.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);

  const handleLogout = () => {
    dispatch(logout());  // Despachar la acción de logout
    navigate('/login');
  };

  const handleEditProfile = () => {
    navigate('/edit-profile');
  };

  const handleGoHome = () => {
    navigate('/');
  };

  const handleGo404 = () => {
    navigate('/my-orders');
  };

  const handleGoChangeKiosko = () => {
    navigate('/select-kiosko');
  };

  const handleGoCart = () => {
    navigate('/cart');
  };

  return (
    <BootstrapNavbar bg="dark" variant="dark" expand="lg" className='mb-5'>
      <BootstrapNavbar.Brand onClick={handleGoHome}>
        <img
          src={logo}
          alt="Logo"
          className="d-inline-block align-top"
          style={{ width: '50px', marginLeft: '10px' }}
        />
        <img
          src={logo2}
          alt="Logo"
          className="saigro-text"
          style={{ width: '120px', marginLeft: '20px', marginTop: '5px' }}
        />
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto buton-submit text-center w-100">
          <Nav.Link onClick={handleGoHome} className='nav-link'>
            <FaBox className='nav-link-icon'/>
            Productos
          </Nav.Link>
          <Nav.Link onClick={handleGo404} className='nav-link'>
            <FaClipboardList className='nav-link-icon'/>
            Mis pedidos
          </Nav.Link>
          <Nav.Link onClick={handleGoCart} className='nav-link'>
            <FaShoppingCart className='nav-link-icon'/>
            Carrito
          </Nav.Link>
          <Nav.Link onClick={handleGoChangeKiosko} className='nav-link'>
            <FaExchangeAlt className='nav-link-icon'/>
            Cambiar kiosko
          </Nav.Link>
        </Nav>
        <Nav className="ms-auto">
          <Dropdown>
            <Dropdown.Toggle variant="link" id="dropdown-basic" className="profile-link">
              <span className="user-name">{user?.username}</span>
              <img
                src={user?.avatar || logoUser}
                alt="User Avatar"
                className="user-avatar"
              />
              <span className="user-profile-text">Perfil</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleEditProfile} className='buton-submit'>
                <FaUserEdit className='dropdown-item-icon'/> Editar perfil
              </Dropdown.Item>
              <Dropdown.Item onClick={handleLogout} className='buton-submit'>
                <FaSignOutAlt className='dropdown-item-icon'/> Cerrar sesión
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default Navbar;
