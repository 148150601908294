import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { selectKiosko } from '../actions/kioskoActions';
import './styles/SelectKiosko.css';

const SelectKiosko = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  
  const handleSelectKiosko = (event) => {
    event.preventDefault();
    const selectedKiosko = event.target.elements.kiosko.value;
    dispatch(selectKiosko(selectedKiosko));
    navigate('/'); // Redirige a la página principal o a otra vista después de seleccionar el kiosko
  };

  const availableKioskos = user.establishments.filter(establishment => establishment.offers_delivery);

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <div className="w-100" style={{ maxWidth: '400px' }}>
        <h2 className="text-center mb-4 ">Selecciona tu Kiosko</h2>
        <Form onSubmit={handleSelectKiosko}>
          <Form.Group>
            <Form.Label className='buton-submit'>Kioskos disponibles</Form.Label>
            <Form.Control as="select" name="kiosko" required >
              {availableKioskos.map(kiosko => (
                <option key={kiosko.uuid} value={kiosko.uuid} >{kiosko.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button className="w-100 mt-5" type="submit">Seleccionar</Button>
        </Form>
      </div>
    </Container>
  );
};

export default SelectKiosko;
