// src/components/SearchBar.jsx
import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import './styles/SearchBar.css';

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  return (
    <Form className="search-bar">
      <FormControl
        type="text"
        placeholder="Buscar productos..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mr-sm-2"
      />
    </Form>
  );
};

export default SearchBar;
